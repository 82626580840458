<template>
  <div class="forbidden">
    <div class="row">
      <v-col cols="12" sm="12" md="9" lg="7" class="mx-auto text-center">
        <v-icon size="40" color="primary" class="forbidden-icon">
          {{ icons.mdiCancel }}
        </v-icon>
        <div class="forbidden-title">{{ $t('forbiddenPageTitle') }}</div>
        <v-btn color="primary" @click="$router.go(-1)">{{ $t('forbiddenPageBtn') }}</v-btn>
      </v-col>
    </div>
  </div>
</template>

<script>
import { mdiCancel } from '@mdi/js';

export default {
  name: 'Error403',
  setup() {
    return {
      icons: {
        mdiCancel,
      },
    };
  },
};
</script>

<style lang="scss">
.forbidden {
  margin-top: 140px;
  &-title {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: #2830ce;
    max-width: 540px;
    margin: 24px auto 40px;
  }
}
</style>
